import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    archivo:{
      id:null,
      nombre:null,
      peso:null,
      mime_type:'IMAGE/JPEG',
      model_id:null,
      model:null,
      usuario_id:1,
    },

    archivos:[],

  }),

  getters:{
    draft:(state) => clone(state.archivo),
  },



  mutations:{

    clear(state){
      state.archivo = {
        id:null,
        nombre:null,
        peso:'',
        mime_type:'IMAGE/JPEG',
        model_id:null,
        model:null,
        usuario_id:1,
      }
    },


    setArchivos(state,archivos){
      if(archivos){
        state.archivos = archivos
      }
    },


    setArchivo(state,archivo){
      if( archivo){
        state.archivo = archivo
      }
    },
    update(state,archivo){
      if(archivo){
        state.archivo = archivo
      }
    },

   

  },


  actions:{

    fetch({commit},archivo_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/archivos/${archivo_id}/fetch-data`).then(({data}) => {
          commit('setArchivo',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/archivos/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit,dispatch},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(k => {
        
        if(k === 'nombre'){
            datos[k].forEach(v => {
              formData.append('nombre[]',v)
            }) 
        }else{
          formData.append(k,datos[k])
        }

      })

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','PUT');
          axios.post(`/api/archivos/${datos.id}`,formData,{headers}).then(({data}) => resolve(data)).catch(e => reject(e))
        }else{
          axios.post(`/api/archivos`,formData,{headers}).then(({data}) => resolve(data)).catch(e => reject(e))
        }
      });

    },

    eliminar({state,commi},archivo_id){
        return new Promise((resolve, reject) => {
          axios.delete(`/api/archivos/${archivo_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
        })
    },

    archivoAdmitido({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/archivos/verificar-archivo-admitido`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      });
    }
    

  }
}