import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    responsable:{
      id:null,
      cargo:'',
      cliente_id:null,
      usuario_id:null,
      cliente:null,
      usuario:null,
      is_gerente:false

    },  

    miResponsabilidad:{
      id:null,
      cargo:'',
      cliente_id:null,
      usuario_id:null,
      cliente:null,
      usuario:null,
      is_gerente:false
    },

    responsables:[]

  }),

  getters:{
    draft:(state) => clone(state.responsable),
    miResponsabilidad:(state) => state.miResponsabilidad,
    isGerente:(state) => state.miResponsabilidad.is_gerente
  },

  mutations:{

    clear(state){
      state.responsable = {
        id:null,
        cargo:'',
        cliente_id:null,
        usuario_id:null,
        cliente:null,
        usuario:null,
        is_gerente:false
      }
    },

    clearMiResponsabilidad(state){
      state.miResponsabilidad = {
        id:null,
        cargo:'',
        cliente_id:null,
        usuario_id:null,
        cliente:null,
        usuario:null,
        is_gerente:false
      }
    },

    setResponsables(state,responsables){
      if(responsables){
        state.responsables = responsables
      }
    },


    setResponsable(state,responsable){
      if( responsable){
        state.responsable = responsable
      }
    },

    setMiResponsabilidad(state,responsable){
      if(responsable){
              state.miResponsabilidad = responsable
      }
    }


  },


  actions:{

    fetch({commit},responsable_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/responsables/${responsable_id}/fetch-data`).then(({data}) => {
          commit('setResponsable',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/responsables/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){


      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/responsables/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/responsables`,datos).then(({data}) => {
            resolve(data)
          }).catch( e => reject(e))
        }
      })
    },

    eliminar({state,commit},responsable_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/responsables/${responsable_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }
  }

}