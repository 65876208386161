// Theme Colors
// Inicialmente estará en blanco. Más adelante, cuando se inicialice la aplicación, le asignaremos colores de arranque desde las variables CSS.
export const $themeColors = {}

// App Breakpoints
// Inicialmente estará en blanco. Más adelante, cuando se inicialice la aplicación, asignaremos puntos de interrupción de arranque a este objeto desde las variables CSS.
export const $themeBreakpoints = {}

import logotipo from '@images/logos/logotipo.png'
import logotipowhite from '@images/logos/logotipo.png'
import logoShort from '@images/logos/logo_short.png'

// APP CONFIG
export const $themeConfig = {
  
  app: {
    appName: 'InfochannelTV', 
    appLogoImage:logotipo,
    applogoImageWhite:logotipowhite,
    appLogoShort:logoShort
  },
  
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed

    menu: {
      hidden: false,
      isCollapsed: false
    },
    
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '' // BS color options [primary, success, etc]
    },
    
    footer: {
      type: 'static' // static, sticky, hidden
    },
    
    customizer: true,
    enableScrollToTop: true
  
  }
}
