import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    carpeta:{
      id:null,
      nombre:null,
      peso:0,
      banco_audiovisual_id:0,
      usuario_id:1,
      banco:null,
      archivos:[]
    },

    carpetas:[],

  }),

  getters:{
    draft:(state) => clone(state.carpeta),
  },



  mutations:{

    clear(state){
      state.carpeta = {
         id:null,
        nombre:null,
        peso:0,
        banco_audiovisual_id:0,
        usuario_id:1,
        banco:null,
        archivos:[]
      }
    },


    setCarpetas(state,carpetas){
      if(carpetas){
        state.carpetas = carpetas
      }
    },


    setCarpeta(state,carpeta){
      if( carpeta){
        state.carpeta = carpeta
      }
    },
    update(state,carpeta){
      if(carpeta){
        state.carpeta = carpeta
      }
    },

   

  },


  actions:{

    fetch({commit},carpeta_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/carpetas/${carpeta_id}/fetch-data`).then(({data}) => {
          commit('setCarpeta',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/carpetas/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit,dispatch},datos){
      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/carpetas/${datos.id}`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
        }else{
          axios.post(`/api/carpetas`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
        }
      })
    },

    eliminar({state,commi},carpeta_id){
        return new Promise((resolve, reject) => {
          axios.delete(`/api/carpetas/${carpeta_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
        })
    }

    

  }
}