import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import '@/assets/scss/app.scss'
Vue.prototype.$eventHub = new Vue();

import '@axios'
import store from './store'
import router from './router'
import App from './App.vue'

// Global Components
import './global-components'
import './filters'


import i18n , {loadLocaleAsync} from '@/libs/i18n'


// 3rd party plugins

import './libs/acl'
import './libs/portal-vue'

import './libs/toastification'
import './libs/sweet-alerts'
import './libs/vue-select'
import './directives'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

import '@/assets/scss/style.scss'

window.clone = (obj)  => JSON.parse(JSON.stringify(obj));

window.random = (min, max) => {
  return Math.round(Math.random() * (max - min) + min);
}

window.redondeo = function (num, decimales = 2) {
  var signo = (num >= 0 ? 1 : -1);
  num = num * signo;

  if (decimales === 0)
    return signo * Math.round(num);

  num = num.toString().split('e');

  num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
  num = num.toString().split('e');
  return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
}


window.colorRand = () => {
  let colores = [
    '#397DAD',
    '#4D616C',
    '#D02412',
    '#CD960E',
    '#348022',
    '#17B6AA',
    '#5F2626',
    '#00AEFF',
    '#6574cd',
    '#9561e2',
    '#f66d9b',
    '#e3342f',
    '#f6993f',
    '#ffed4a',
    '#38c172',
    '#4dc0b5',
    '#6cb2eb',
    '#444444',
    '#00c0ef',
  ];


  var i = random(0, colores.length);


  return colores[i];

}

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery



import _ from 'lodash';

window._ = _;

// Laravel Echo
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost:import.meta.env.VITE_REVERB_HOST,
    wsPort:80,
    wssPort:443,
    forceTLS:true,
    enabledTransports: ['ws','wss'],
    authorizer:(channel,options) => {
      return {
        authorize: (socketId,next) => {
          axios.post('/broadcasting/auth',{
            socket_id:socketId,
            channel_name:channel.name
          }).then(response => {
            next(null,response.data)
          }).catch(error => next(error))

        }
      }
    }
});


Vue.config.productionTip = import.meta.env.DEV;
Vue.config.silent = import.meta.env.PROD;
Vue.config.devtools = import.meta.env.DEV;
import {computed} from 'vue';

let app = new Vue({
  store,
  data:() => ({
    api_uri:import.meta.env.VITE_API_URL,
    // loading:computed(() => store.state.loading)
  }),
  router,
  i18n,
  render: h => h(App),
  provide:() =>  ({swal : Vue.swal,i18n:i18n,loadLocaleAsync:loadLocaleAsync})
}).$mount('#app')
